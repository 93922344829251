<script lang="ts">
  export let image = 'https://via.placeholder.com/500'
  export let link = '#'
  export let alt = "example image"
</script>

<div class="contact-card">
  <a href={link} target="_blank">
    <img id="contact-card-image" src={image} alt={alt} />
  </a>
</div>

<style>
  .contact-card {
    display: flex;
    width: 10rem;
    border: none;
    border-radius: 5px;
    margin: 2.5rem;
    flex-direction: column;
    flex: auto;
  }

  #contact-card-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
</style>