<script lang="ts">
  import ProjectCard from '../components/ProjectCard.svelte'
  // import { Svg } from './svelte-svg';
  import SuryAsset_1 from '../../public/images/Sury_asset_1.svelte'
  import SuryAsset_2 from '../../public/images/Sury_asset_2.svelte'

  // Import any other required components or functions here ^^
</script>

<main>
  <div class="profile-container">
    <div class="info">
      <h1 class="name">Sury Bagenal</h1>
      <p class="tagline">Fashion Design Consultant</p>
      <!-- <button class="button-50">CV</button> -->
    </div>
    <div class="svg">
      <!-- <img id="profile-pic" src="./images/sury_profile.png" alt="sury profile" /> -->
      <!-- moved to about page -->
      <SuryAsset_1 />
    </div>
  </div>
  <div class="portfolio-container">
    <h2>My Portfolio</h2>
    <div class="projects">
      <ProjectCard
        title="Alyson Walsh x Seasalt"
        image="/images/Alyson_Walsh.png"
        link="/pdfs/Alyson_Walsh.pdf"
      />
      <ProjectCard
        title="Womenswear @ Seasalt"
        image="/images/Breathing_Space.png"
        link="/pdfs/Breathing_Space.pdf"
      />
      <ProjectCard
        title="Menswear @ Seasalt"
        image="/images/Menswear.png"
        link="/pdfs/Menswear.pdf"
      />
      <ProjectCard
        title="ME+EM"
        image="/images/ME_+_EM.png"
        link="/pdfs/ME_+_EM.pdf"
      />
      <!-- Add more ProjectCards as needed -->
    </div>
  </div>
</main>

<style>
  .profile-container {
    display: flex;
    width: 100%;
    border-radius: 0;
    /* background-color: #87cbb9; */
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: 0;
    padding: 0 1rem;
    box-sizing: border-box;
  }

  .svg {
    width: 50%;
    height: 10%;
    /* background-color: #87cbb9; */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: center; /* Center children horizontally */
    justify-content: center; /* Center children vertically */
  }

  .name {
    margin: 2rem 0;
    color: #a17259;
    /* font-family: 'Bodoni Book Italic', serif; */
  }

  .tagline {
    font-size: 2em;
    margin: 2rem 0;
    justify-content: flex-start;
  }

  .portfolio-container {
    background-color: #569daa;
    width: 100%;
    /* max-width: 800px; */
    margin: 0;
    padding: 2rem;
  }

  .portfolio-container h2 {
    margin: 2rem;
  }

  .projects {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  }
</style>
