<script lang="ts">
  import CompanyLogos from './CompanyLogos.svelte';
</script>

<main>
  <div class="title-container">
    <h1>About Me</h1>
    <h4>I believe in People, Planet, Profit.</h4>
  </div>
  <div class="body-container">
    <img id="profile-pic" src="./images/sury_profile.png" alt="sury profile" />
    <div class="section">
      <p>
        I am an apparel designer with over 25 years experience in the fashion
        industry who believes that we can make a positive change through
        sustainable design.
      </p>
      <p>
        I have significant experience helping SME's and Brands create
        sustainable apparel collections that are modern and on trend, whilst
        also delivering their brand values and business objectives. From
        strategic concept to successful online sales.
      </p>
      <p>
        From designing luxury and premium collections through to driving the
        vision and critical path. Creating the mood and colour, sourcing fabric
        development and working with our suppliers and the Tech team, to ensure
        that garments were executed by factories according to design intent,
        with consistency of fit and finish across seasons.
      </p>
    </div>
    <div class="section">
      <h5>Previous Clients Include:</h5>
      <CompanyLogos />
    </div>
  </div>
</main>

<style>
  #profile-pic {
    padding: 1.5rem;
    display: block;
    margin: 0 auto;
    max-height: 30rem;
    max-width: 30rem;
    border-radius: 50%;
  }

  .section h5 {
    margin: 2rem;
  }
</style>
