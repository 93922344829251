<script lang="ts">
    const companies = [
       { name: "Me+Em", logo: "/images/logo-meem-1000.png" },
       { name: "Seasalt", logo: "/images/logo-seasalt-cornwall-1000.png" },
       { name: "House of Fraser", logo: "/images/logo-house-of-fraser-1000.png" },
       { name: "Designers at Debenhams", logo: "/images/debenhams-logo-736x545.png" },
       { name: "Monsoon", logo: "/images/monsoon-logo-400.png" }
    ];
</script>

<main>
  <div class="logo">
    <ul>
      {#each companies as { name, logo }}
        <li>
          <img src={logo} alt={name} />
        </li>
      {/each}
    </ul>
  </div>
</main>

<style>
  .logo ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      list-style-type: none;
      padding: 0;
    }

    .logo ul li {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .logo img {
      max-width: 15rem;
      height: 15rem;
      /* height: auto; */
      margin: 1rem;
      /* object-fit: cover; */
    }
</style>
