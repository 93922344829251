<script lang="ts">
  import { Link } from 'svelte-routing'
</script>

<nav>
  <Link to="/">Home</Link>
  <Link to="/about">About</Link>
  <Link to="/contact">Contact</Link>
</nav>

<style>
  nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #374873;
    display: flex;
    justify-content: space-around;
    padding: 1rem;
    z-index: 100;
  }

  a {
    color: #FA9884;
    text-decoration: none;
    font-size: 1.2rem;
  }

  a:hover {
    color: #ff3e00;
  }
</style>
