<script lang="ts">
  import ContactIcons from "../components/ContactIcons.svelte";
</script>

<main>
  <div class="title-container">
    <h1>Contact</h1>
    <h4>Available for short and long term projects.</h4>
  </div>
  <div class="body-container">
    <div class="section">
      <p>To arrange a free short introductory call and find out how we can work together, reach out via the following:</p>
    </div>
    <div class="contact-icons">
      <ContactIcons
        image="/images/linkedin_blue.png"
        link="https://www.linkedin.com/in/sury-bagenal-09b6aa8/"
        alt="LinkedIn"
      />
      <ContactIcons
        image="/images/email_blue.png"
        link="mailto:sury@bagenal.co.uk"
        alt="Email"
      />
      <ContactIcons
        image="/images/insta_blue.png"
        link="https://www.instagram.com/suryb/"
        alt="Instagram"
      />
    </div>
    <div class="footer">
      <footer>
        <a href="https://www.flaticon.com/free-icons" title="free icons"><p>Icons created by Uniconlabs - Flaticon</p></a>
      </footer>
    </div>
  </div>
  <div class="footer">
    
</main>

<style>
  .body-container p {
    margin: 2.5rem;
    text-align: center;
    line-height: 1.6;
  }

  .contact-icons {
    width: auto;
    display: flex;
  }

  .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
  }

  .footer a {
    color: #374873;
  }

  .footer p {
    font-size: 0.75rem;
  }
</style>
