<script lang="ts">
  export let title = 'Project Title'
  // export let description = 'Project Description'
  export let image = 'https://via.placeholder.com/500'
  export let link = '#'
</script>

<div class="card">
  <a id="card-link" href={link} target="_blank">
    <img src={image} alt={title} />
  </a>
  <div class="card-body">
    <h3 class="card-title">{title}</h3>
    <!-- <p class="card-description">{description}</p> -->
    <a href={link} target="_blank">View Project</a>
  </div>
</div>

<style>
  .card {
    width: 18.75rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 1rem;
    display: flex;
    flex-direction: column;
    flex: auto;
  }

  .card img {
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  .card-body {
    padding: 1rem;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .card-title {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }

  .card-description {
    font-size: 1rem;
    margin-bottom: 1rem;
  }

  .card-body a {
    text-decoration: inherit;
  }

  .card-body a:hover {
    text-decoration: underline;
  }

  #card-link {
    text-decoration: none;
    flex: 0 0 auto;
  }
</style>
