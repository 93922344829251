<script lang="ts">
  import { Router, Route, Link } from 'svelte-routing'
  import Home from './pages/Home.svelte'
  import About from './pages/About.svelte'
  import Contact from './pages/Contact.svelte'
  import Navbar from './components/Navbar.svelte'
</script>

<Router>
  <Navbar />
  <Route path="/" let:params component={Home} />
  <Route path="/about" let:params component={About} />
  <Route path="/contact" let:params component={Contact} />
</Router>

<style>
  /* @media (min-width: 480px) {
    h1 {
      max-width: none;
    }

    p {
      max-width: none;
    }
  } */
</style>
